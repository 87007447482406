import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AppBar, Avatar, Button, Toolbar, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import sidekick from '../../images/sidekick.png';
import useStyles from './styles';

const Navbar = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        history.push('/');
        setUser(null);
    }

    useEffect(() => {
        const token = user?.token;

        if (token) {
            const decodedToken = decode(token);

            if(decodedToken.exp * 1000 < new Date().getTime()) logout();
        }

        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <div className={classes.brandContainer}>
                <Typography 
                    component={Link} 
                    to="/" 
                    className={classes.heading} 
                    variant="h2" 
                    align="center"
                >
                    Sidekick
                </Typography>

                <img 
                    className={classes.image} 
                    src={sidekick} 
                    alt="Sidekick Logo" 
                    height="60" 
                />

            </div>

            <Toolbar className={classes.toolbar}>
                { 
                    user ? (
                        <div className={classes.profile}>
                            <Avatar 
                                className={classes.purple} 
                                alt={user.result.name} 
                                src={user.result.imageUrl}
                            >
                                {user.result.name.charAt(0)}
                            </Avatar>

                            <Typography 
                                className={classes.userName} 
                                variant='h6'
                            >
                                {user.result.name}
                            </Typography>

                            <Button 
                                variant="contained" 
                                className={classes.logout} 
                                color="secondary"
                                onClick={logout}
                            >
                                Logout
                            </Button>

                        </div>) : (
                            <Button 
                                component={Link} 
                                to="/auth" 
                                variant="contained" 
                                color="primary"
                            >
                                Log In
                            </Button>
                        )
                }
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;