import React, { useState } from 'react';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icon from './icon';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from './styles';
import Input from './Input';
import { register, login } from '../../actions/auth';

const intialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const Auth = () => {
    const classes = useStyles();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [formData, setFormData] = useState(intialState);
    const dispatch =  useDispatch();

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (isRegister) {
            dispatch(register(formData, history))
        } else {
            dispatch(login(formData, history))
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const switchMode = () => {
        setIsRegister((prevIsRegister) => !prevIsRegister);
        setShowPassword(false);
    };

    const googleSuccess = async (res) => {
        const result = res?.profileObj;
        const token = res?.tokenId;

        try {
            dispatch({ type: 'AUTH', data: { result, token } });
            history.push('/');
        } catch (error) {
            console.log(error);
        }
    };

    const googleFailure = (error) => {
        console.log(error);
        console.log('Google Log In Failed. Try Again');
    };

  return (
    <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
            <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h5">
                {isRegister ? 'Register' : 'Log In'}
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {
                        isRegister && (
                            <>
                                <Input 
                                    name="firstName" 
                                    label="First Name" 
                                    handleChange={handleChange} 
                                    autoFocus 
                                    half 
                                />
                                <Input 
                                    name="lastName" 
                                    label="Last Name" 
                                    handleChange={handleChange} 
                                    half 
                                />
                            </>
                        )
                    }
                    <Input 
                        name="email" 
                        label="Email Address" 
                        handleChange={handleChange} 
                        type="email" 
                    />
                    <Input 
                        name="password" 
                        label="Password" 
                        handleChange={handleChange} 
                        type={showPassword ? "text" : "password"} 
                        handleShowPassword={handleShowPassword} 
                    />
                    { 
                        isRegister && 
                            <Input 
                                name="confirmPassword" 
                                label="Confirm Password" 
                                handleChange={handleChange} 
                                type="password" 
                            />
                    }
                </Grid>

                <Button 
                    type="submit" 
                    fullWidth variant="contained" 
                    color="primary" 
                    className={classes.submit}
                >
                    {isRegister ? 'Register' : 'Log In'}
                </Button>

                <GoogleLogin 
                    clientId="548300806044-l3bq3iss424er0vfs95l5ifpm154a3fp.apps.googleusercontent.com"
                    render={(renderProps) => (
                        <Button 
                            className={classes.googleButton} 
                            color="primary" 
                            fullWidth 
                            onClick={renderProps.onClick} 
                            disabled={renderProps.disabled} 
                            startIcon={<Icon />} 
                            variant="contained"
                        >
                            Log In with Google
                        </Button>
                    )}
                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy="single_host_origin"
                />

                <Grid container justifyContent="center">
                    <Grid item>
                        <Button onClick={switchMode}>
                            { isRegister ? 'Already have an account? Log In' : "Don't have an account? Register" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    </Container>
  );
};

export default Auth;